<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProcessingPayment',
  render() {
    // This component does not render anything.
    return null
  },
  computed: {
    ...mapGetters({
      company: 'company'
    })
  },
  watch: {
    'company.paymentStatus'() {
      if (this.company && this.company.paymentStatus && this.company.paymentStatus === 'subscription') {
        window.location.href = '/payment/success'
      }
    }
  }
}
</script>
